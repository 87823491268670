import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BlogView from './BlogView';

const mapStateToProps = state => ({
  ...state.blog,
});

export default withRouter(connect(mapStateToProps)(BlogView));
