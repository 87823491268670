// @flow

import React from 'react';
import Pagination from 'react-js-pagination';

import './Pagination.scss';

type Props = {
  activePage: number,
  itemsCountPerPage: number,
  totalItemsCount: number,
  onChange: (newPage: number) => void,
};

export default (props: Props) => (
  <div>
    <Pagination
      activePage={props.activePage}
      itemsCountPerPage={props.itemsCountPerPage}
      totalItemsCount={props.totalItemsCount}
      onChange={props.onChange}
    />
  </div>
);
