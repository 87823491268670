import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BlogAuthor from 'modules/visitor/blog/components/blogAuthor';
import styles from './Post.scss';

const Post = ({ data }) => (
  <Link to={`/blog/${data.post_name}`} className={styles.post}>
    <div className={styles.thumbnail} style={{ backgroundImage: `url('${data.thumbnail}')` }} />
    <div className={styles.detail}>
      <div className={`${styles.postTitle} has-text-primary`}>{data.post_title}</div>
      <BlogAuthor data={data} />
    </div>
  </Link>
);

Post.propTypes = {
  data: PropTypes.shape({
    post_title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Post;
