import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { mediumDate } from 'utils/datetime';
import ProfileLink from 'components/link/ProfileLink';
import styles from './BlogAuthor.scss';

const BlogAuthor = props => {
  const { data, size } = props;

  return (
    <article className={classnames('media', styles[size])}>
      <div className="is-pulled-left mr-10">
        <img
          className={styles.avatar}
          src={data.author_id ? `/images/profile/Id${data.author_id}-ProfilPic.jpg` : data.author_avatar}
          alt="avatar"
        />
      </div>
      <div className="media-content">
        {data.author_id && data.author_town && data.author_speciality ? (
          <div className={styles.authorName}>
            <ProfileLink
              profileType="specialist"
              town={data.author_town}
              speciality={data.author_speciality}
              slug={data.author_slug}
            >
              {data.author_name}
            </ProfileLink>
          </div>
        ) : (
          <div className={styles.authorName}>{data.author_name}</div>
        )}
        <div className={styles.postDate}>{mediumDate(data.post_date)}</div>
      </div>
    </article>
  );
};

BlogAuthor.defaultProps = {
  size: 'small',
};

BlogAuthor.propTypes = {
  data: PropTypes.shape({
    author_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    author_name: PropTypes.string,
    author_avatar: PropTypes.string,
    post_date: PropTypes.string,
  }).isRequired,
  size: PropTypes.string,
};

export default BlogAuthor;
