// @flow

import * as React from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { push } from 'react-router-redux';
import queryString from 'query-string';
import type { RouterAction } from 'react-router-redux';
import type { ContextRouter } from 'react-router';
import type { Dispatch } from 'redux';

import type { BlogDetail } from 'types';
import type { BlogViewActionsFlowType } from 'modules/visitor/blog/blogView/actions';
import Pagination from 'components/pagination';
import HorizontalMenu from 'modules/visitor/horizontalMenu';
import Post from './post';
import { actions } from './actions';

type Props = {
  ...ContextRouter,
  dispatch: Dispatch<RouterAction | BlogViewActionsFlowType>,
  blogs: Array<BlogDetail>,
  pageSize: number,
  total: number,
};

type State = {
  keyword: string,
  page: number,
};

class BlogView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.getBlogs(props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.location.search !== nextProps.location.search) {
      this.getBlogs(nextProps);
    }
  }

  getBlogs(props: Props) {
    const search = queryString.parse(props.location.search);
    const keyword: string = (search.keyword: any);
    const page: number = (search.page: any);

    this.state = {
      keyword: keyword || '',
      page: page || 1,
    };

    // dispatch get blogs
    this.props.dispatch(actions.getBlogs(keyword, page));
  }

  handleKeywordSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props.dispatch(
      push({
        pathname: '/blog',
        search: queryString.stringify({
          page: '1',
          keyword: this.state.keyword,
        }),
      })
    );
  };

  handlePageChanged = (newPage: number) => {
    const search = queryString.parse(this.props.location.search);

    this.props.dispatch(
      push({
        pathname: '/blog',
        search: queryString.stringify({
          page: newPage.toString(),
          keyword: search.keyword || '',
        }),
      })
    );
  };

  handleKeywordInput = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      keyword: e.currentTarget.value,
    });
  };

  render() {
    const { blogs, total, pageSize } = this.props;
    const { page, keyword } = this.state;

    return (
      <I18n>
        {({ i18n }) => (
          <div>
            <HorizontalMenu />
            <div className="container">
              <div className="column is-10 is-offset-1 pb-60">
                <div className="columns mt-40 mb-20">
                  <h2 className="column has-text-primary is-size-2 is-pulled-left">
                    <Trans>Latest articles</Trans>
                  </h2>

                  <form className="column is-4 is-pulled-right mt-10" onSubmit={this.handleKeywordSubmit}>
                    <input
                      type="text"
                      className="input is-medium"
                      placeholder={i18n._(t`Search`)}
                      value={keyword}
                      onChange={this.handleKeywordInput}
                    />
                  </form>
                </div>

                <div className="columns is-multiline pb-20">
                  {blogs.map(item => (
                    <div key={item.ID} className="column is-4">
                      <Post data={item} />
                    </div>
                  ))}
                </div>

                <Pagination
                  activePage={page * 1}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={total}
                  onChange={this.handlePageChanged}
                />
              </div>
            </div>
          </div>
        )}
      </I18n>
    );
  }
}

export default BlogView;
